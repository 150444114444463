<template>
    <div class="page bg-white recordQueryBox">
        <div class="flex_l_c srhBox">
            <SelectTree class="selectBox" ref="menuParentTree"
                        :props="defaultProps"
                        :data="classifyList"
                        :value="searchForm.archivesBasicDataId"
                        :clearable="false"
                        :accordion="true"
                        @getValue="(value) => {searchForm.archivesBasicDataId=value}"/>
            <el-input class="el-input__inner2" v-model="searchForm.keyWord" clearable maxlength="50"
                      placeholder="请输入关键词进行检索，多个关键词用空格分开（限50字）" @keyup.enter.native="search(1)"></el-input>
            <el-button type="primary" @click="search(1)">检索</el-button>
            <el-button @click="reset()">重置</el-button>
        </div>
        <div class="tips">本次检索耗时1.43s，共为您找到相关结果约{{total}}个</div>
        <el-button icon="el-icon-star-off" type="primary" size="small"
                   v-show="hasPermission('fulltextRetrieval:collect') && dataList.length"
                   @click="collect()">加入收藏
        </el-button>
        <el-button icon="el-icon-download" size="small" v-show="hasPermission('fulltextRetrieval:download')"
                   @click="downloadData()" v-if="dataList.length">批量下载</el-button>
        <div class="m_t1" v-loading="loading">
            <div class="itemBox" v-if="dataList.length > 0">
                <div v-for="(item, index) in dataList" :key="index" class="item flex_l_c">
                    <el-checkbox v-model="item.checked" @change="checkedChange(item)"></el-checkbox>
                    <div class="itemInfo flex1 pointer" @click="view(item, 'view')">
                        <div class="flex_l_c">
                            <img :src="getFileIcon(item.docType)" class="fileIcon">
                            <div class="fileName omit flex1" v-html="item.docName"></div>
                        </div>
                        <div class="p_r">
                            <div class="omit5" v-html="item.docContent"></div>
                            <div class="noJurisdiction"
                                 v-if="!hasPermission(item.archivesBasicDataId + '-queryPermissions-1') && !hasPermission(item.archivesDataId + '-queryPermissions-1') && !hasPermission(item.archivesBasicDataId + '-downloadPermissions-1') && !hasPermission(item.archivesDataId + '-downloadPermissions-1') && !hasPermission(item.archivesBasicDataId + '-printPermissions-1') && !hasPermission(item.archivesDataId + '-printPermissions-1')">
                                暂无文件权限
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination v-if="dataList.length > 0"
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           :current-page="searchForm.current"
                           :page-sizes="[10, 20, 50, 100]"
                           :page-size="searchForm.size"
                           :total="total"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <div class="noData" v-else>
                <img src="@/assets/img/noData.png">
                <div>暂无数据</div>
            </div>
        </div>
        <ViewPage ref="viewPage"></ViewPage>
    </div>
</template>

<script>
    import SelectTree from '@/components/treeSelect/treeSelect.vue'
    import ViewPage from '../recordation/listForm'

    export default {
        components: {
            SelectTree, ViewPage
        },
        data() {
            return {
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                classifyList: [],
                searchForm: {
                    archivesBasicDataId: '',
                    keyWord: '',
                    size: 10,
                    current: 1,
                },
                config: [],
                dataList: [],
                loading: false,
                total: 0,
                dataListSelections: [],
            }
        },
        watch: {
            'searchForm.archivesBasicDataId'(val) {
                this.search(1)
            }
        },
        mounted() {
            this.getClassifyList()
        },
        methods: {
            // 检索
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.archivesBasicDataId = p.archivesBasicDataId == '1' ? null : p.archivesBasicDataId
                this.$axios(this.api.record.searchArchivesDoc, p, 'post').then(res => {
                    if (res.status) {
                        this.dataList = res.data.records
                        this.dataList.map(item => {
                            this.$set(item, 'checked', false)
                        })
                        this.total = parseInt(res.data.total)
                        this.checkedData()
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })

            },
            // 获取档案分类
            getClassifyList() {
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.classifyList = res.data
                        this.classifyList.unshift({
                            archivesBasicName: '全部',
                            id: '1',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.searchForm.keyWord = this.$route.query.keyWord
                            let id = this.$route.query.archivesBasicDataId
                            this.searchForm.archivesBasicDataId = id
                        })
                    }
                })
            },
            // 校验是否选中
            checkedData() {
                this.dataList.map(item => {
                    this.dataListSelections.map(val => {
                        if(val.id == item.id) {
                            this.$set(item, 'checked', true)
                        }
                    })
                })
            },
            // 选择收藏
            checkedChange(item) {
                if (item.checked) {
                    this.dataListSelections.push(item)
                } else {
                    this.dataListSelections = this.dataListSelections.filter(v => v.id != item.id)
                }
            },
            // 加入收藏
            collect() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let list = this.dataListSelections.map(v => {
                    return {
                        archivesBasicDataId: v.archivesBasicDataId,
                        archivesDataId: v.archivesDataId
                    }
                })
                this.$confirm(`确定加入收藏吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.archivespersonalfavoritesSave, list, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('收藏成功')
                            this.search(0)
                        } else {
                            this.$message.error('收藏失败')
                        }
                    })
                })
            },
            // 查看
            view(item, method) {
                if (this.hasPermission(item.archivesBasicDataId + '-queryPermissions-1') || this.hasPermission(item.archivesDataId + '-queryPermissions-1') || this.hasPermission(item.archivesBasicDataId + '-downloadPermissions-1') || this.hasPermission(item.archivesDataId + '-downloadPermissions-1') || this.hasPermission(item.archivesBasicDataId + '-printPermissions-1') || this.hasPermission(item.archivesDataId + '-printPermissions-1')) {
                    this.$refs.viewPage.init(item, method, item.archivesBasicDataId, 'fulltext')
                } else {
                    this.$message.info('暂无文件权限')
                }
            },
            // 批量下载
            downloadData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (this.dataListSelections.length > 50) {
                    this.$message.warning('最多选择50条数据')
                    return
                }
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确认下载所选数据？'),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let ids = this.dataListSelections.map(item => {
                        return item.docFileId
                    }).join(',')
                    this.$axios(this.api.record.downloadArchivesFilesZip, {
                        archivesFiles: ids,
                    }, 'get', 'blob').then(res => {
                        let blob = new Blob([res.data], {type: 'application/zip'})
                        let link = document.createElement('a')
                        link.download = decodeURI('下载附件')
                        link.href = window.URL.createObjectURL(blob)
                        link.click()
                    })

                });
            },
            //重置
            reset() {
                this.dataListSelections = []
                this.searchForm.archivesBasicDataId = '1'
                this.searchForm.keyWord = ''
                this.search(1)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        },
    }
</script>

<style scoped lang="scss">
    .bg-white {
        overflow-y: auto !important;
    }
    .recordQueryBox {
        .srhBox {
            width: 75%;
            margin: 0;

            .selectBox {
                width: 350px;
            }
        }
    }

    .tips {
        font-size: 13px;
        color: #999999;
        padding: 12px 0;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 15px;
    }

    .tips2 {
        font-size: 13px;
        color: #999999;
        padding-left: 30px;
    }

    .itemBox {
        .item {
            margin-bottom: 15px;
            color: #333;
        }

        .itemInfo {
            margin-left: 10px;
            padding: 15px;
            border: 1px solid #eeeeee;
            position: relative;
        }

        .fileIcon {
            width: 26px;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .fileName {
            font-size: 16px;
            margin-bottom: 10px;
        }

        .noJurisdiction {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.4);
            font-size: 28px;
            color: #FFFFFF;
            text-align: center;
            padding-top: 24px;
        }
    }

</style>
